import logo from './assets/logo512.png';
import android from './assets/android-download-button.png';
import ios from './assets/appstorebutton.jpg';
import file from './GVE_MAXEX_v0_0_5.apk';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Tap here to download<br></br> the GVI MAXEX app on your phone. <br></br> <br></br>Version: v0.0.5
        </p>
        <div className='container'>
          <a href={file}
            download="GVI MAXEX App"
            target="_blank"
            rel="noreferrer"
          ><img src={android} className='button-img' alt="logo" /></a>
          {/* <a href="https://apps.apple.com/us/app/itunes-connect/id376771144"><img src={ios} className='button-img' alt="logo" /></a> */}
        </div>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
